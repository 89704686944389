import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CidadaniaItaliana = ({ data }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const posts = data.allMdx.edges
  const pageTitle = "Cidadania Italiana"
  const convertPost = node => {
    const title = node.frontmatter.title || node.fields.slug
    return (
      <li key={node.fields.slug}>
        <Link style={{ boxShadow: `none` }} to={`/${node.fields.slug}`}>
          {title}
        </Link>
      </li>
    )
  }

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={pageTitle}
        description="Página com diferentes artigos sobre cidadania italiana e direitos e deveres
  do cidadão italiano."
      />
      <div className="post-wrapper">
        <h1 itemProp="headline">{pageTitle}</h1>
        <div className="post-text">
          <p>Olá!</p>
          <p>
            Esta página foi criada para que você possa acompanhar todos os
            textos relacionados com cidadania italiana, divididos pela fase em
            que eles fazem mais sentido.
          </p>
          <p>
            Espero que eles sejam úteis, mas lembre-se: este blog não é
            especializado em cidadania italiana, logo, você deve sempre
            verificar em fontes oficiais para confirmar se não houve alguma
            alteração em relação ao aqui apresentado.
          </p>
          <p>Boa leitura!</p>
          <h2>Antes do reconhecimento</h2>
          <ul>
            {posts
              .filter(post =>
                post.node.frontmatter.tags.includes("Antes do reconhecimento")
              )
              .map(({ node }) => convertPost(node))}
          </ul>
          <h2>Após o reconhecimento</h2>
          <ul>
            {posts
              .filter(post =>
                post.node.frontmatter.tags.includes("Já reconhecido")
              )
              .map(({ node }) => convertPost(node))}
          </ul>
          <h2>Bônus</h2>
          <ul>
            {posts
              .filter(post =>
                post.node.frontmatter.tags.includes("Já reconhecido")
              )
              .map(({ node }) => convertPost(node))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default CidadaniaItaliana

export const pageQuery = graphql`
  {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Cidadania italiana" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
